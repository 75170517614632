import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { BasePermission } from './base.permission';
import {
    AuthenticationProvider,
    PermissionsProvider
} from 'src/app/core';

@Injectable({
    providedIn: 'root',
})
export class StatusMonitorPermission extends BasePermission implements CanActivate {

    constructor(
        router: Router,
        authenticationService: AuthenticationProvider,
        private permissionsProvider: PermissionsProvider) {
        super(authenticationService, router);
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        await this.canActivateBase();

        if (this.permissionsProvider.statusMonitor) {
            return true;
        }
        return this.navigate();
    }

}

