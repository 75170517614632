import {
    Router
} from '@angular/router';
import { AuthenticationProvider } from '@app/core';
import { EULA_ROUTE } from '@app/shared/constants';
import { AuthRoutesConstants } from '../../constants';

export class BasePermission {

    constructor(
        protected authenticationProvider: AuthenticationProvider,
        private router: Router) {
    }

    protected async canActivateBase() {
        const isAuth = await this.authenticationProvider.tryLogin();
        if (isAuth && !this.authenticationProvider.currentUserValue.licenseOptions.endUserLicenseAgreement) {
            this.router.navigate([EULA_ROUTE]);
        }
    }

    protected navigate() {
        return this.router.navigate([AuthRoutesConstants.NOT_AUTHORIZED_ROUTE], { skipLocationChange: true });
    }

}

