import { Injectable } from '@angular/core';
import {
    Router
} from '@angular/router';
import { AuthenticationProvider } from 'src/app/core';
import {
    HOME_UNAUTHORIZED_ROUTE,
} from '@appConstants';

@Injectable({
    providedIn: 'root',
})
export class DefaultGuard {

    constructor(
        private authenticationProvider: AuthenticationProvider,
        private router: Router) {
    }

    async canActivate() {
        const isAuthenticated = this.authenticationProvider.isAuthenticated();
        if (isAuthenticated) {
            await this.authenticationProvider.loadUserProfile();
            this.router.navigate([this.authenticationProvider.getDefaultRoutApp()]);
        } else {
            this.router.navigate([HOME_UNAUTHORIZED_ROUTE])
        }
        return isAuthenticated;
    }
}
